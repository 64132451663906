@import url('https://fonts.googleapis.com/css2?family=Open+Sans&family=Oswald&display=swap');

  .page-header {
    position: relative;
    background-color: #3B1F1C;
    top: 0;
    left: 0;
    padding: 0;
    margin: 0;
    width: 100%;
    min-height: 76px;
    z-index: 10;
    border: none;
    transition: height .5s;
}
#logo img {
    margin-left: auto;
    margin-top: 20px;
    max-width: 300px;
}
.logo {
}

.bg-reservas {
    background-size:     cover;
    background-repeat:   no-repeat;
    background-position: center top;
}
.bg-reservas:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 350px;
    background: linear-gradient(
            180deg
            ,rgba(59,31,28,.85) 0,rgba(59,31,27,0) 25%);
    z-index: 1;
}
#title {
    color: #FFF8E8;
}
#title h1 {
    font-size: 60px;
    margin: 0;
}
#title p {
    font-family: Oswald;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-size: .75em;
}

.btn-outline-primary {
    color: #FFF8E8 !important;
    background-color: #BB4129 !important;
    border-color: #BB4129 !important;
    height: 75px;
}

.btn-check:checked + .btn-outline-primary {
    background-color: #f6bf53 !important;
    border-color: #f6bf53 !important;
}
.btn-primary {
    background-color: #3B1F1C !important;
    color: #FFF8E8 !important;
    border-color: #3B1F1C !important;
}
.btn {
    height: 75px;
    width: 100%;
}