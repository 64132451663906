body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.preloader {
    font-family: helvetica, sans-serif;
    font-weight: bold;
}

.bg_top {
    background-position: center center;
    width: 100%;
    background-size: 100% auto;
}
:root {
    --jumbotron-padding-y: 3rem;
}

.jumbotron {
    padding-top: var(--jumbotron-padding-y);
    padding-bottom: var(--jumbotron-padding-y);
    margin-bottom: 0;
    background-color: #fff;
}
.mt-n100 {
    margin-top: -35px;
}

@media (min-width: 768px) {
    .jumbotron {
        padding-top: calc(var(--jumbotron-padding-y) * 5);
        padding-bottom: calc(var(--jumbotron-padding-y) * 5);
    }
    .logo{
        min-height: 64px;
        max-height: 256px;
        min-width: 64px;
        max-width: 256px;
    }
    .mt-n64 {
        margin-top: -48px;
    }
    .mt-logo {
        margin-top: -48px;
    }
    .mt-64 {
        margin-top: 48px;
    }
    .mt-name-restaurant {
        margin-top: -32px;
    }
}

.jumbotron p:last-child {
    margin-bottom: 0;
}

.jumbotron-heading {
    font-weight: 300;
}

.jumbotron .container {
    max-width: 40rem;
}

.box-shadow { box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05); }

#map {
    height: 400px;
    /* The height is 400 pixels */
    width: 100%;
    /* The width is the width of the web page */
}

/* Center align the text within the three columns below the carousel */
.marketing .col-lg-4 {
    margin-bottom: 1.5rem;
    text-align: center;
}
.marketing h2 {
    font-weight: 400;
}
.marketing .col-lg-4 p {
    margin-right: .75rem;
    margin-left: .75rem;
}

